<template>
  <div>
    <!-- Alerta para vídeo em processamento -->
    <vs-alert
      v-if="videoProcessing"
      :title="$t('atencao')"
      color="rgb(231, 154, 23)"
      active="true"
      class="text-warning"
    >
      {{ $t('video-ainda-esta-em-processamento') }}
    </vs-alert>

    <!-- Player de vídeo -->
    <div class="vjs-custom-skin">
      <video
        ref="videoPlayer"
        class="video-js"
        playsinline="true"
        crossorigin="anonymous"
        @timeupdate="onTimeUpdate"
      >
        <source :src="options.src" :type="options.type" />
      </video>
    </div>

    <!-- Placeholder para vídeos em processamento -->
    <edu-placeholder-action
      v-if="videoWaitProcessing"
      @action="$router.go()"
      :label="$t('o-video-ainda-esta-sendo-processado')"
      class="absolute z-50 placeholder-backdrop text-white bottom-0"
    />
  </div>
</template>

<script>
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import 'video.js/dist/video-js.css'
import videojs from 'video.js'

export default {
  components: {
    EduPlaceholderAction
  },
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          liveDisplay: false
        },
        liveui: false,
        html5: {
          hls: {
            overrideNative: true // Força o Video.js a não usar o player nativo
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      },
      currentVideoTime: null,
      retryCount: 0,
      maxRetries: 3,
      retryDelay: 2000
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer
    },
    videoRendered() {
      return this.options.video_progress >= 100
    },
    videoProcessing() {
      return this.options.video_progress >= 0 && this.options.video_progress < 100
    },
    videoWaitProcessing() {
      return this.isEmpty(this.options.video_progress)
    }
  },
  methods: {
    setupVideo() {
      if (!this.videoRendered) this.playerOptions.autoplay = false

      // Configurando o Video.js com as opções ajustadas
      this.videoPlayer = videojs(this.player, this.playerOptions)

      this.addEventBarCustom()
      this.videoPlayer.on('fullscreenchange', this.handleFullscreenChange)

      // Capturar erros de reprodução
      this.videoPlayer.on('error', this.handleVideoError)

      // Adicionando a fonte de vídeo após a configuração
      this.videoPlayer.src({
        src: this.options.src,
        type: this.options.type
      })
    },
    addEventBarCustom() {
      this.videoPlayer.controlBar.progressControl.el_.classList.add('grid', 'grid-rows-2')
      const eventBarCustom = this.videoPlayer.controlBar.progressControl.addChild('eventBarCustom', {
        events: this.options.events
      })

      if (eventBarCustom && typeof eventBarCustom.renderTooltips === 'function') {
        eventBarCustom.renderTooltips(this.options.events)
      }
    },
    handleFullscreenChange() {
      this.videoPlayer.controlBar.progressControl.removeChild('eventBarCustom')
      this.addEventBarCustom()
    },
    handleVideoError() {
      const error = this.videoPlayer.error()
      if (error && error.code === 2 && this.retryCount < this.maxRetries) {
        console.warn(`Retrying video load... Attempt ${this.retryCount + 1}`)
        this.retryCount++
        setTimeout(() => {
          this.videoPlayer.src(this.options.src)
          this.videoPlayer.load()
          this.videoPlayer.play()
        }, this.retryDelay)
      } else if (this.retryCount >= this.maxRetries) {
        console.error('Max retries reached. Unable to load video.')
      }
    },
    onTimeUpdate(event) {
      this.currentVideoTime = event.target.currentTime
    },
    getCurrentTime() {
      return this.currentVideoTime
    }
  },
  mounted() {
    this.setupVideo()
  },
  beforeDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.off('fullscreenchange', this.handleFullscreenChange)
      this.videoPlayer.dispose()
    }
  }
}
</script>

<style lang="scss">
@import './Player.scss';

.video-js {
  position: inherit;
  height: 480px;
  width: 100%;
}

/* Estilizações para tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  min-width: 300px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
